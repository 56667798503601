import { ConfigProvider } from 'antd';
import './App.css';
import Home from './pages/Home';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1CA262'
        },
        components: {
          Layout: {
            headerBg: '#1CA262'
          }
        }
      }}>
      <Home />
    </ConfigProvider>
  );
}

export default App;
