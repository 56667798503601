import { Layout, Image } from 'antd';
import Logo from '../assets/logo.png'



const Header = ({ children }) => {
  return (
    <Layout>
      <Layout.Header className="flex align-middle">
        <Image src={Logo} preview={false} width={180} height={60} />
      </Layout.Header>
      <Layout.Content>
        {children}
      </Layout.Content>
    </Layout>
  )
}

export default Header